import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { waterPortalInfo, validatePortal } from 'factories/WaterToolsFactory';
import { mikeCouldAxios, defaultAxios as axios } from '../useAxios';

const tokenKey = 'AccessToken';
const mainKey = 'main';
const portalKey = 'portal';

// TODO do not set anything here.....
const setBaseURL = (props: { baseURL?: string }) => {
  const { baseURL } = props;
  if (baseURL) {
    axios.defaults.baseURL = baseURL || '';
  }
};

const setApiKey = (props: { apiKey?: string; projects?: string; token: string; projectId?: string }) => {
  const { apiKey, projects, token, projectId } = props;
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  if (apiKey) {
    mikeCouldAxios.defaults.headers.common.OpenApiKey = apiKey;
    mikeCouldAxios.defaults.headers.common.Projetcs = projects;
    mikeCouldAxios.defaults.headers.common['dhi-open-api-key'] = apiKey;
  }
  if (projectId) {
    mikeCouldAxios.defaults.headers.common.projectId = projectId;
  }
};
interface TokenDS {
  sub: string;
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid': string;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': string;
}

interface Token {
  sub: string;
  userGroups: string;
  name: string;
  token: any;
}

const decodeAccesTokenApi = ({ accessToken }): Token => {
  const { token } = accessToken;
  try {
    const decodeToken: TokenDS = jwt_decode(token);
    const userGroups = decodeToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid'];
    const name = decodeToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/name'];
    const { sub } = decodeToken;
    return { token, userGroups, name, sub };
  } catch {
    return { token: undefined, userGroups: '', name: '', sub: '' };
  }
};

interface TokenPortal {
  email: string;
  nameid: string;
  iss: string;
}

const decodePortalToken = (portalToken: string) => {
  const decodeToken: TokenPortal = jwt_decode(portalToken);
  return decodeToken;
};

interface Options {
  country?: string;
  filter?: string;
  tileDataset?: string;
  show?: boolean;
  projects?: string;
  styleMapbox: string;
  baseURL?: string;
  ne?: string[];
  sw?: string[];
  projectId?: string;
  ignoreObservation?: boolean;
  apiKey: string;
}

interface Portal {
  options: Options;
  email: string;
  nameid: string;
  workspaceId: string;
  baseURL: string;
}

const portalLogged = (sessionAccess: any, portal: Portal) => {
  const { accessToken: moKey } = sessionAccess || { token: '' };
  const access = decodeAccesTokenApi({ accessToken: { token: moKey } });
  const { token } = access;
  const { options, email, nameid, workspaceId } = portal;
  const { baseURL, apiKey } = options;
  setApiKey({ token, apiKey });
  axios.defaults.baseURL = baseURL || '';

  return { access, pending: false, options, email, nameid, dhiOpenApiKey: '', workspaceId };
};

const normalLogged = (sessionAccess: any) => {
  const { accessToken: moKey, Name: username } = sessionAccess || { token: '' };
  const access = decodeAccesTokenApi({ accessToken: { token: moKey } });
  const { token, sub: nameid } = access;
  setApiKey({ token });
  return {
    access,
    pending: false,
    options: {} as Options,
    email: '',
    nameid,
    username,
    dhiOpenApiKey: '',
    workspaceId: '',
  };
};

const useAppInfo = () => {
  const [state, setState] = useState<any>({
    pending: true,
    options: {} as Options,
    access: {} as Token,
    dhiOpenApiKey: '',
    email: '',
    iss: '',
    nameid: '',
    workspaceId: '',
  });
  const [isPortal, setIsPortal] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const portalTokenQuery = query.get('portalToken') || '';
  const appTokenQuery = query.get('appToken') as string;

  const callapikey = useCallback(() => {
    axios.get('/api/systems/openapikey').then(({ data }) => {
      if (data) {
        mikeCouldAxios.defaults.headers.common.OpenApiKey = data;
        mikeCouldAxios.defaults.headers.common['dhi-open-api-key'] = data;
      }
    });
  }, []);

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      if (!!portalTokenQuery && appTokenQuery) {
        setIsPortal(true);
        const { email, nameid, iss } = decodePortalToken(portalTokenQuery);
        const appToken: any = jwt_decode(appTokenQuery || '');
        const { ApplicationId, WorkspaceId, PortalContainer } = appToken || {};
        waterPortalInfo(WorkspaceId, ApplicationId, iss, portalTokenQuery, PortalContainer).then((res: any) => {
          const {
            country,
            filter,
            show,
            tileDataset,
            projects,
            apiKey,
            styleMapbox,
            baseURL,
            ne,
            sw,
            ignoreObservations,
            projectId,
          } = res;
          setBaseURL(res);

          validatePortal(portalTokenQuery, WorkspaceId).then((validation) => {
            // pass baseURL before setting it up globally...
            const access = decodeAccesTokenApi(validation);
            const { token } = access;
            setApiKey({ apiKey, projects, token, projectId });
            const portalState: any = {
              access,
              options: {
                country,
                filter,
                show,
                tileDataset,
                projects,
                styleMapbox,
                apiKey,
                ne,
                sw,
                ignoreObservations,
                baseURL,
              },
              pending: false,
              dhiOpenApiKey: apiKey,
              nameid,
              email,
              workspaceId: WorkspaceId,
            };

            localStorage.setItem(portalKey, JSON.stringify({ options: res, email, nameid, workspaceId: WorkspaceId }));
            setState(portalState);
          });
        });
      } else if (localStorage.getItem(tokenKey) && localStorage.getItem(portalKey)) {
        setIsPortal(true);
        const portalState = portalLogged(
          JSON.parse(localStorage.getItem(tokenKey) || ''),
          JSON.parse(localStorage.getItem(portalKey) || ''),
        );
        setState(portalState);
      } else {
        let portalState = { options: {}, pending: false };
        if (localStorage.getItem(tokenKey)) {
          portalState = normalLogged(JSON.parse(localStorage.getItem(tokenKey) || ''));
        }
        axios.defaults.baseURL = '';
        if (process.env.REACT_APP_BASEURL) {
          localStorage.setItem(mainKey, process.env.REACT_APP_BASEURL);
          axios.defaults.baseURL = process.env.REACT_APP_BASEURL;
        } else if ((window as any).env.BASEURL !== '__BASE_URL__') {
          localStorage.setItem(mainKey, (window as any).env.BASEURL || '');
          axios.defaults.baseURL = (window as any).env.BASEURL;
        }
        callapikey();
        setState(portalState);
      }
    }
  }, [isPortal, firstTime, appTokenQuery, portalTokenQuery, callapikey]);

  return {
    isPortal,
    ...state,
  };
};
export default useAppInfo;
export { mikeCouldAxios };
