import axios from '../containers/hooks/useAxios';

const path = 'https://watertools-gateway-api.azurewebsites.net';

export const waterPortalInfo = async (workspaceId, applicationId, iss, portalTokenQuery, apiUrl) => {
  let newPath;
  if (iss === 'https://watertools-portal.azurewebsites.net/') {
    newPath = path;
  } else {
    newPath = apiUrl;
  }
  const url = `${newPath}/v2/workspace/${workspaceId}/application/${applicationId}/focus-area`;
  const headers = {
    Authorization: `Bearer ${portalTokenQuery}`,
  };
  let responseFocusArea;
  try {
    responseFocusArea = await axios.get(url, { headers });
  } catch (error) {
    console.error('Error:', error);
  }
  const { data: areas } = responseFocusArea;
  if (areas.length > 0) {
    const { id } = areas[0];
    const response = await axios.get(`${url}/${id}/connectionstringinfo`);
    const { data } = response;
    const { connectionstring } = data;

    const query = new URLSearchParams(connectionstring);
    const country = query.get('country');
    const ne = query.get('ne') ? JSON.parse(query.get('ne')) : undefined;
    const sw = query.get('sw') ? JSON.parse(query.get('sw')) : undefined;

    const baseURL = query.get('apiURL');

    const filter = query.get('filter');
    const show = query.get('show');
    const tileDataset = query.get('tileDataset');
    const projectId = query.get('projectId');
    const ignoreObservations = query.get('ignoreObservations');
    const projects = query.get('projects');
    const apiKey = query.get('apiKey');
    const styleMapbox = query.get('styleMapbox');
    return {
      country,
      ne,
      sw,
      baseURL,
      filter,
      show,
      tileDataset,
      projects,
      projectId,
      apiKey,
      styleMapbox,
      ignoreObservations,
    };
  }
  return {};
};

export const validatePortal = async (portalToken, workspaceId) => {
  const url = `/api/portal/validateWorkspaceRole?token=${portalToken}&workspaceId=${workspaceId}`;
  const response = await axios.put(url, {});
  const { data } = response;
  return data;
};
